<template>
    <div class="coating">
        <div class="calendar">
            <div class="first">
                <img :src="coatObj.gcMainUrl?coatObj.gcMainUrl:require('@/assets/img/bg_nor.png')" alt="">
            </div>
            <div class="chain"></div>
            <div class="second">
                <div class="scratch" v-if="coatingFinish == 1">
                    <div class="scratch-cot">
                        <vue-scratch-card
                                element-id='scratchWrap'
                                :ratio=0.7
                                :move-radius=20
                                :clearCallback="completeFunction"
                                coverColor="#f00"
                                :coverImg="img"
                                result=""
                        >
                            <div slot='result'>
                                <div class="super">惊喜马上到</div>
                            </div>
                        </vue-scratch-card>
                    </div>
                    <p>有效刮开截止时间至：{{this.common.formatDate(coatObj.expireTime, '{y}-{m}-{d}')}}</p>
                    <p class="warning">刮开之后不退不换</p>
                </div>
                <div class="prize" v-if="coatingFinish == 2">
                    <ul>
                        <li v-if="syncUpdateObj.gcShape === 0 || syncUpdateObj.gcShape === 3">
                            <span>卡号：</span>
                            <p>{{syncUpdateObj.cardNo}}</p>
                            <strong v-clipboard:copy="syncUpdateObj.cardNo"
                                    v-clipboard:success="copySuccess"
                                    v-clipboard:error="copyError">复制</strong>
                        </li>
                        <li v-if="syncUpdateObj.gcShape === 0 || syncUpdateObj.gcShape === 1">
                            <span>卡密：</span>
                            <p>{{syncUpdateObj.cardPwd}}</p>
                            <strong v-clipboard:copy="syncUpdateObj.cardPwd"
                                    v-clipboard:success="copySuccess"
                                    v-clipboard:error="copyError">复制</strong>
                        </li>
                        <li v-if="syncUpdateObj.gcShape === 3">
                            <span>链接：</span>
                            <p><a :href="syncUpdateObj.fullNewShortUrl">{{syncUpdateObj.fullNewShortUrl}}</a></p>
                            <strong v-clipboard:copy="syncUpdateObj.fullNewShortUrl"
                                    v-clipboard:success="copySuccess"
                                    v-clipboard:error="copyError">复制</strong>
                        </li>
                        <li v-if="syncUpdateObj.gcShape === 3">
                            <span>验证码：</span>
                            <p>{{syncUpdateObj.verifyCode}}</p>
                            <strong v-clipboard:copy="syncUpdateObj.verifyCode"
                                    v-clipboard:success="copySuccess"
                                    v-clipboard:error="copyError">复制</strong>
                        </li>
                        <li>商品有效期：{{this.common.formatDate(syncUpdateObj.effectiveEndTime, '{y}-{m}-{d}')}}</li>
                    </ul>
                </div>
                <div class="error" v-if="coatingFinish == 0">
                    <img :src="error.img">
                    <!--<ul>-->
                    <!--<li>-->
                    <!--<h5>具体原因：</h5>-->
                    <!--<strong>商品已冻结</strong>-->
                    <!--</li>-->
                    <!--<li>-->
                    <!--<h5>提示内容：</h5>-->
                    <!--<span>商品正在售后中用</span>-->
                    <!--</li>-->
                    <!--</ul>-->
                    <p v-html="error.msg"></p>
                </div>
            </div>
        </div>
        <div class="explain" v-if="coatObj.gcText">
            <h3>商品详情</h3>
            <div v-html="coatObj.gcText"></div>
        </div>
        <van-dialog class="coating-dialog" v-model="dialog.show" :show-confirm-button="false">
            <div class="hint">{{dialog.msg}}</div>
        </van-dialog>
    </div>
</template>
<script>
    import {checkCoating, verifyCoating, getOutStorageInfo} from "@/api";

    export default {
        component: {},
        data() {
            return {
                code: this.$route.query.code || undefined,
                coatObj: {},
                syncUpdateObj:{
                    gcShape:-1,
                },
                coatingFinish: 1,//0：显示错误信息，1：显示未兑换界面，2：显示已兑换界面
                canConvert: true,
                img: require("@/assets/img/bg_coating.png"),
                error:{
                    img:require("@/assets/img/bg_error.png"),
                    msg:'商品不存在'
                },
                dialog: {
                    show: false,
                    msg: '正在快马加鞭加载中，请稍后...'
                },
            }
        },
        created() {
            if (this.$route.query.code) {
                this.CheckCode()
            }else{
                this.$router.push({name:'Error'})
            }
        },
        mounted() {
            // if (window.history && window.history.pushState) {
            //     // 向历史记录中插入了当前页
            //     history.pushState(null, null, document.URL);
            //     window.addEventListener('popstate', this.goBack, false);
            // }
        },
        methods: {
            copySuccess() {
                this.$toast({
                    message: '复制成功',
                })
            },
            copyError() {
                this.$toast({
                    message: '复制失败',
                })
            },
            async completeFunction() {
                //刮开后回调
                let {phone, token} = this.coatObj
                let obj = {
                    phone,
                    token,
                    redeCodeNo: this.code
                }
                this.dialog.show = true;
                try {
                    let res = await verifyCoating(obj);
                    if (res.code === 0) {
                        this.coatObj.couponNo = res.data;
                        await this.syncUpdate();
                    } else {
                        this.error.img = require("@/assets/img/bg_out_error.png");
                        this.error.msg = '商品出库中，请耐心等待！';
                        this.coatingFinish = 0;
                    }
                    this.dialog.show = false;
                }catch (e) {
                    this.dialog.show = false;
                }
                // verifyCoating(obj).then(res => {
                //     if (res.code === 0) {
                //         this.coatObj = Object.assign(this.coatObj, res.data);
                //         this.syncUpdate();
                //     } else {
                //         this.errorMsg = res.message;
                //         this.coatingFinish = 0;
                //     }
                //     this.dialog.show = false;
                // }).catch(error => {
                //     this.dialog.show = false;
                // })
            },
            async CheckCode() {
                this.error.img = require("@/assets/img/bg_error.png");
                this.coatingFinish = 1;
                this.dialog.show = true;
                try{
                    let res = await checkCoating(this.code)
                    if (res.code === 0) {
                        if (res.data.routeFlag === 0) {
                            //未使用
                            this.coatObj = res.data;
                            this.coatingFinish = 1;
                        } else if (res.data.routeFlag === 2) {
                            //已使用
                            this.coatObj = res.data;
                            await this.syncUpdate();
                        } else {
                            //兑换类型不匹配
                            this.coatingFinish = 0;
                            this.error.msg = '商品不支持刮码，请到兑换页面使用！'
                        }
                    } else {
                        //失败
                        this.coatingFinish = 0;
                        if (res.data && res.data.checkRedeemCodeErrorCode){
                            switch (res.data.checkRedeemCodeErrorCode.code) {
                                case -1:{
                                    this.error.msg = '商品不存在！';
                                }
                                    break
                                case 1:{
                                    this.error.msg = '商品出库中，请耐心等待！'
                                }
                                    break
                                case 2:{
                                    this.error.msg = '商品正在售后中，不可以使用！'
                                }
                                    break
                                case 3:{
                                    this.error.msg = '该商品已退款退券，无法使用！'
                                }
                                    break
                                case 4:{
                                    this.error.msg = '商品已禁用，不可以使用！'
                                }
                                    break
                                case 5:{
                                    this.coatObj = res.data;
                                    await this.syncUpdate();
                                }
                                    break
                                case 6:{
                                    this.error.msg = '刮开时间已过期，过期不退不换！'
                                }
                                    break
                                case 7:{
                                    this.error.msg = `刮开时间未开始：<br>${this.common.formatDate(res.data.effectiveStartTime,'{y}-{m}-{d}')}~${this.common.formatDate(res.data.effectiveEndTime,'{y}-{m}-{d}')}`
                                }
                                    break
                                case 8:{
                                    this.error.msg = '商品类型不匹配'
                                }
                                    break
                                case 9:{
                                    this.error.msg = '兑换页面类型不匹配'
                                }
                                    break
                                case 10:{
                                    this.error.msg = '兑换手机号码信息不存在'
                                }
                                    break
                                case 11:{
                                    this.error.msg = '此兑换码已激活'
                                }
                                    break
								default:{
								  this.dialog.msg = res.message
								}
								break
                            }
                        }else{
                            this.error.msg = res.message;
                        }
                    }
                    // console.log('提早去除');
                    this.dialog.show = false;
                }catch (error) {
                    this.dialog.show = false;
                }

                // checkCoating(this.code).then(res => {
                //     if (res.code === 0) {
                //         if (res.data.routeFlag === 0){
                //             //未使用
                //             this.coatObj = res.data;
                //             this.coatingFinish = 1;
                //         }
                //         else if (res.data.routeFlag === 2){
                //             //已使用
                //             this.coatObj = res.data;
                //             this.coatingFinish = 2;
                //         } else{
                //             //兑换类型不匹配
                //             this.coatingFinish = 0;
                //             this.errorMsg = '直充商品不支持刮码，请到兑换页面使用！'
                //         }
                //     } else {
                //         //失败
                //         this.errorMsg = res.message;
                //         this.coatingFinish = 0;
                //         this.dialog.show = false;
                //     }
                // }).catch(error =>{
                //     this.dialog.show = false;
                // })

            },
            syncUpdate() {
                return new Promise((resolve,reject) =>{
                    getOutStorageInfo(this.coatObj.couponNo,this.code).then(res => {
                        if (res.code === 0) {
                            this.dialog.show = false;
                            this.syncUpdateObj = res.data;
                            if (res.data.isJumpShortUrl === 1) {
                                window.location.replace(res.data.fullNewShortUrl);
                            }else{
                                this.coatingFinish = 2;
                            }
                        }
                        this.dialog.show = false;
                        // console.log('延迟去除');
                        resolve();
                    }).catch(error => {
                        this.dialog.show = false;
                        resolve();
                    })
                })
            },
        }
    }
</script>
<style lang="less" scoped>
    @import '~@/assets/style/coating.less';
</style>
